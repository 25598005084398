
import { Delete, Edit, View, Search } from "@element-plus/icons-vue";
import { getCookie, setCookie } from "../../utils/tools";
import { client } from '@/client';
import { ElMessage, ElMessageBox } from 'element-plus';
import { defineComponent } from 'vue';
import { ReqList } from "@/shared/adminprotocols/balance/PtlList";
import { BalanceData, BalanceOptions, BalanceType } from "@/shared/types/BalanceData";
export default defineComponent({
    name: "admin-index",
    setup() {
      return {
        Edit,
        Delete,
        View,
        Search,
      };
    },
    data: () => {
      return {
        roles: BalanceOptions,
        roleSelected:['0'] as any[],
        keywords: "",
        userid:0,
        tableData:[] as BalanceData[],
        multipleSelection: [] as any[],
        count: 0,
        cur: 1,
        username: "",
        pageSize:10
      };
    },
    computed: {},
    created() {
      this.username = getCookie("username")||"";
      this.list();
    },
    methods: {
        handleChange(){

            console.error(this.roleSelected[0]);
        },
      clearSearch() {
        this.$router.replace({
          name: "balance-index",
          query: { cur: 1, cid: 0, keywords: "" },
        });
      },
      doSearch() {
        this.$router.replace({
          name: "balance-index",
          query: { cur:1, keywords: this.keywords },
        });
        this.list();
      },
      //查询
      async list() {
        try {
            let data:ReqList = {cur:this.cur,pageSize:this.pageSize};
            let type = this.roleSelected[0];
            if (type != 0) {
                data.type = type;
            }
            if(this.keywords != ""){
                data.key = this.keywords;
            }
            if (this.userid != 0) {
                data.userid = this.userid;
            }
          let res = await client.callApi("balance/List",data);
          if (res.isSucc) {
              this.tableData = [...res.res.list];
            this.count = res.res.count;
          }
        } catch (error) {
          console.log(error);
        }
      },
  
      //翻页
      handleCurrentChange(e: number) {
        this.cur = e;
        this.list();
      },
  
      toggleSelection(rows: any[]) {
            let refs:any = this.$refs.multipleTable;
        if (rows) {
          rows.forEach((row: any) => {
            if (refs) {
              refs.toggleRowSelection(row);
            }
          });
        } else {
              refs.clearSelection();
        }
      },
  
      handleSelectionChange(val: never[]) {
        this.multipleSelection = val;
      },
  
      //编辑
      toEdit(e: { id: any; }) {
        let id = e.id;
        this.$router.push({ name: "balance-edit", params: { id: id } });
      },
  
    delSome() {
        let ids = this.multipleSelection.map((item) => {
        return parseInt(item.id);
        });
        ElMessageBox.confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        })
        .then(async () => {
        let res = await client.callApi("balance/Del",{ids:ids});
        if (res.isSucc) {
            ElMessage({
                message: "删除成功 ^_^",
                type: "success",
            });
            this.list();
            }
        })
        .catch(() => {
            ElMessage({
            type: "info",
            message: "已取消删除",
            });
        });
    },
        handleSizeChange(val: { pageSize: number; }){
        this.pageSize = val.pageSize;
        this.list();
        },
        formatterType(row:BalanceData) {
            let str = "平台充值";
            switch (row.type) {
                case BalanceType.admin:
                    str = "平台充值";
                    break;
                case BalanceType.advert:
                    str = "广告奖励";
                    break;
                case BalanceType.game:
                    str = "游戏消耗";
                    break;
                case BalanceType.register:
                    str = "注册赠送";
                    break;
                case BalanceType.shared:
                    str = "分享赠送";
                    break;
            }
            return str;
        },
  
      //删除
        async handleDel(e: { id: any; }) {
            let id = e.id;
            try {
            ElMessageBox.confirm("这么疯狂，要删数据？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                let res = await client.callApi("balance/Del",{ids:[id]});
                // let res = await del(id);
                if (res.isSucc) {
                    ElMessage({
                    message: "删除成功 ^_^",
                    type: "success",
                    });
                    this.list();
                }
                })
                .catch(() => {
                ElMessage({
                    type: "info",
                    message: "已取消删除",
                });
                });
            } catch (error) {
            console.log(error);
            }
        },
        handleChangeSort(e:{prop:any,order:'descending'|"ascending"|null}){
            console.error(e);
            this.list();
        }
    },
  });
  